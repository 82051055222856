import React from "react"

const CareerOpportunities = ({ careerOpportunity }) => {

  return (
    <>
    <section className="content">
<div className="content-block">
<div className="eighty-spacer"></div>
<div className="centered-content-container">
<div className="centered-title-holder">
<h1>
All Vacancies
 </h1>
</div>
</div>
</div>
</section>
    <section className="content">
   <div className="content-block">
      <ul className="vacancies-mixit-container scroll-to-filter">
      {careerOpportunity[0].vacancies.nodes.length
        ? careerOpportunity[0].vacancies.nodes.map(prod => (
             <li className="mix asset-finance ">
             <div className="vacancy-one-block-container clearfix">
                    <a href={`/blog/vacancy/${prod.slug}`}>
                       <h3>{prod.title}</h3>
                    <div className="vacancy-details-inner">
                    {prod.vacancyCategories.nodes.length
                      ? prod.vacancyCategories.nodes.map(vacancy => (
                          <span>{vacancy.name} | </span>
                    ))
                    : null}
                    {prod.vacancyDepartments.nodes.length
                      ? prod.vacancyDepartments.nodes.map(department => (
                          <span>{department.name}</span>
                    ))
                    : null}</div>
                    <div className="vacancy-details-inner">Posted: {prod.vacancyDetails.datePosted} | {prod.vacancyDetails.applicationBy ?
                      <>
                      Closing: {prod.vacancyDetails.applicationBy}
                      </>
                      : null
                    }</div>
                     <span className="vacancy-block-content" dangerouslySetInnerHTML={{ __html: prod.excerpt }}/>

                     <div className="find-out-more">
                            Read more

                     </div>
                     </a>
                    </div>
          </li>
        ))
      : null}
      </ul>

      <div className="eighty-spacer"></div>


   </div>



</section>
</>
  )
}

export default CareerOpportunities
